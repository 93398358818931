// ScrollItems.js
import React from 'react';
import '../global.css';
import './research.css';

const Research = ({ title, urls }) => {
    return (
        <div className="scroll-items bg-dark vp-container pt-4">
            {/* Your scroll items/content go here */}
        <div class="row  gap-5">
            <div class="col-md">
                <span class='custom-large-heading light-col'>Research</span>
            </div>
            <div class="col-md">
                <span class='montser-sml-LightItalic light-col ft-section-heading'>Explore Scientific Research showcasing cutting-edge studies and advancements that are shaping the future of educational science and technology</span>
                <a href="/research" className="nav-link montser-mdm-2 text-yellow d-flex align-center justify-content-start mt-5 mb-5" rel="noopener noreferrer">
                <span class="text-decorator-underline">View more Research posts</span>
                </a>
            </div>
        </div>
            <div className="row text-yellow pt-2 gx-5 img-spacing">
                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1001756" className="text-decoration-none" target="_blank">
                            <img src='images/Computerized cognitive training in cognitively healthy older adults.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>
                            <div className='lh-lg mt-3'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Lampit, A., Hallock, H., & Valenzuela, M. (2014).</span><br></br>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Computerized cognitive training in cognitively healthy older adults: A systematic review and meta-analysis of effect modifiers.</span><br></br>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">PLoS Medicine, 11(11), e1001756</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.1037/a0037507" className="text-decoration-none" target="_blank">
                            <img src='images/Video game training enhances cognition of older adults.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Toril, P., Reales, J. M., & Ballesteros, S. (2014).</span><br></br>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Video game training enhances cognition of older adults: A meta-analytic study.</span> <br></br>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Psychology and Aging, 29(3), 706-716 </span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.3389/fnhum.2012.00183" className="text-decoration-none" target="_blank">
                            <img src='images/Brain training in progress_A review of trainability in healthy seniors.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg d-flex flex-column mt-3'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Buitenweg, J. I., Murre, J. M., & Ridderinkhof, K. R. (2012).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Brain training in progress: A review of trainability in healthy seniors.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Frontiers in Human Neuroscience, 6, 183</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-12">
                    <hr className='mt-8rem'></hr>
                </div>
            </div>
        </div>
    );
};

export default Research;