
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Corporation = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("corporationSectionActive");
  }, []);

  return (
    // <>

    <div>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='body-color-1'>
        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Corporations</h4>
            <hr className='mt-5'></hr>
          </section>
          <section className='vp-section'>
            <div className='row'>
              <div className='col-sm-6 flex-column align-items-start inner-container gap-5'>

                <div className='mb-0 w-100'>
                  <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                    <div className='job-width lh-lg '>
                      <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>JOB:</h5>
                      <span className='montser-mdm-2 common-textshadow ft-2-5vh' >IMPROVE THE HIRING PROCESS </span>
                      <p className='montser-mdm common-textshadow  ft-3vh'>By leveraging scientifically-backed methods to evaluate prospects, saving time of interviews, and increasing the quality of matches.</p>
                    </div>
                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                  </div>
                </div>


                <div className='mb-0 w-100'>
                  <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                    <div className='job-width lh-lg '>
                      <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>JOB:</h6>
                      <span className='montser-mdm-2 common-textshadow ft-2-5vh'>OPTIMIZE PROFESSIONAL DEVELOPMENT</span>
                      <p className='montser-mdm common-textshadow  ft-3vh'>By ensuring that your employees are learning exactly what they need in order to exceed the expectations of their role. Increase your team’s attention to detail while providing the critical knowledge they need to succeed.</p>
                    </div>
                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                  </div>
                </div>


                <div className='mb-0 w-100'>
                  <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                    <div className='job-width lh-lg '>
                      <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>JOB:</h5>
                      <span className='montser-mdm-2 common-textshadow ft-2-5vh'  >MAXIMIZE PRODUCTIVITY</span>
                      <p className='montser-mdm common-textshadow  ft-3vh'>By developing the skills of your team effectively while saving time and minimizing costly mismatched hires and mistakes.</p>
                    </div>
                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                  </div>
                </div>

              </div>
              <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                  <img src='images/Corporations_Image_1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img me-auto" />
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className='vp-section'>
          <div className='justify-content-center'>
            <div className=' d-flex flex-column align-items-start text-left gap-5'>
              <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
              Experience the future of corporate training and professional development!</p>
              <div className="width-100 ">
                <img src='images/Widescreen Corporations_1.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
              </div>
            </div>
          </div>
        </section>


        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Our cutting-edge, AI-driven platform streamlines the learning process, delivering personalized content tailored to your employees' unique needs. 
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                  <img src='images/Enhanced Learning AI.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-6 block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                    Boost performance and productivity through real-time assessments and optimal learning experiences.  
                  </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-7'>
                <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                  <img src='images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img  ms-auto" />

                </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0">
                    <p className="montser-sml-LightItalic lh-lg ft-3vh">
                      Boost performance and productivity through real-time assessments and optimal learning experiences.  
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>



          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Enhance your hiring process by efficiently identifying top talent and providing targeted training to bridge skill gaps.
                    </p>
                  </div>
                </div>
                <div className='d-flex align-items-center pb-3 justify-content-center'>
                  <button type="button" className="outline-lightgreen py-0 montser-mdm-2 rounded-top rounded-0 btn btn-outline-light d-none d-none">
                    <span className='text-yellow'>Meet us</span>
                  </button>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                  <img src='images/Classroom Image 3.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img  ms-auto" />

                </div>
              </div>
            </div>
          </section>



          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-6 block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                    Invest in Virtu Prep and drive your organization's success through continuous learning, development, and innovation.
                  </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-7'>
                <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                  <img src='images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img  ms-auto" />

                </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0">
                    <p className="montser-sml-LightItalic lh-lg ft-3vh">
                      Invest in Virtu Prep and drive your organization's success through continuous learning, development, and innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>



          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                      Hit the Meet Us button to schedule a demonstration with one of our dedicated Team Members.
                    </p>
                  </div>
                </div>
                <div className='d-flex align-items-center pb-3 justify-content-center'>
                  <button type="button" className="outline-lightgreen py-0 montser-mdm-2 rounded-top rounded-0 btn btn-outline-light d-none d-none">
                    <span className='text-yellow'>Meet us</span>
                  </button>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                  <img src='images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img  ms-auto" />

                </div>
              </div>
            </div>
            <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                          <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Corporation;

