
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Managementsolutions = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("managementsolutionsSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-management'>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Skill Development</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                    <div className='mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg '>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Critical Thinking Enhancement </span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Sharpens analytical skills and critical thinking abilities, empowering students to tackle complex problems and make reasoned decisions under exam conditions.</p>  
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Strategic Reasoning Training</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Focuses on developing strategic reasoning capabilities, teaching students how to approach questions logically and efficiently to maximize their test performance.</p>  
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Foundational Knowledge Building</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Strengthens core academic skills, laying a solid foundation of knowledge that supports advanced learning and test-taking strategies.</p>  
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>            
                  </div>
                  <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                      <img src='/images/Teachers.jpeg' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Advance Your Abilities with Neo Test Prep's Skill Development</p>
                <div className="width-100 ">
                  <img src='/images/Skill Development Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

         

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Elevate your problem-solving skills with targeted training in critical thinking and analysis.</p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Elevate your problem-solving skills with targeted training in critical thinking and analysis.</p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic common-textshadow text-start lh-lg ft-3vh'>
                    Learn strategic approaches to test questions, enhancing your ability to reason through complex scenarios.</p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Build a strong foundation of essential knowledge, crucial for success in standardized testing and beyond.
                </p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Build a strong foundation of essential knowledge, crucial for success in standardized testing and beyond.
                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic common-textshadow text-start lh-lg ft-3vh'>
                    Strengthen your academic base, preparing you for higher-level challenges and opportunities.</p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Classroom Image 3.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Transform your test-taking strategy with advanced skills that set you apart from the competition.</p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/school image 3.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Transform your test-taking strategy with advanced skills that set you apart from the competition.</p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic common-textshadow text-start lh-lg ft-3vh'>
                    Click 'Meet Us' to discover how Skill Development at Neo Test Prep can enhance your performance and broaden your academic horizons.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/school image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
              <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                          <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
            </div>
          </section>


          
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Managementsolutions;

