// Students.js
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import './aboutus.css';
import VideoBackground from '../components/VideoBackground';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Aboutus = () => {
    const dynamicVideoLink = "videos/Sequence 01_2.mp4";
    const pageName = "aboutPage"
    const buttonText = "Home"

    useEffect(() => {
        const elements = document.getElementsByClassName("text-yellow");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(".text-dark1");
        }
        const section = document.getElementsByClassName("mainSectionDiv")[0];
        section.classList.add("aboutSectionActive");
    }, []);

    return (
        // <>

        <div className=''>
            {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
            {/* <> */}
            <Navbar />
            <VideoBackground
                videoLink={dynamicVideoLink}
                buttonText={buttonText}
                pageName={pageName}
            />
            <div style={{ height: '100%', paddingTop: '20px' }} className='bg-body-about'>
                <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
                    <div className='content-margin '>
                       
                       
                       
                        <div className='mt-2 pt-3'>
                        <heding className='montser-lrg-bold align-items-center py-4'>The Future of Test Preparation: Personalized, Strategic Mastery </heding>

                        <p className='align-items-center monster-lrg-normal p-gap ' style={{ paddingTop: '1.5rem' }}>
                        Welcome to Neo Test Prep, your gateway to mastering standardized tests through a revolutionary approach that combines personalized learning strategies, advanced analytics, and a focus on strategic test-taking skills. At Neo Test Prep, we understand the pivotal role standardized tests play in educational and professional advancement. Our mission is to transform test preparation from a daunting challenge into an empowering journey, tailored to meet the unique needs and goals of each learner.
                       </p>
                       
           
                        <heding className='montser-lrg-bold align-items-center py-4'>Our Vision: Elevating Test Performance through Personalization and Strategy</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        In an era where test scores can open doors to prestigious educational institutions and coveted career opportunities, Neo Test Prep stands as a pillar of innovation and excellence. We reject the one-size-fits-all approach to test prep, advocating instead for a personalized pathway that recognizes and amplifies each student's strengths while addressing their challenges. 
                         </p>                       
                                                
                         <p className='align-items-center monster-lrg-normal p-gap '>
                        Our strategic approach to test preparation emphasizes not just content mastery, but also critical thinking, time management, and effective test-taking strategies, ensuring our students are fully equipped to excel.
                        </p>
                        

                        <heding className='montser-lrg-bold align-items-center py-4'>Empowering Learners with Cutting-Edge Tools and Insights:</heding>

                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Neo Test Prep harnesses the power of AI and advanced data analytics to offer a test preparation experience that is both intelligent and intuitive. By analyzing thousands of data points from individual performance metrics, we craft customized study plans that dynamically adjust to each learner's progress, strengths, and areas for improvement.
                        </p>
                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Our platform incorporates engaging, interactive elements that make preparation not just effective but enjoyable.
                        </p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        We believe in the power of informed preparation. Our platform provides detailed feedback and analytics, offering students and their supporters a transparent view of progress and areas for targeted improvement. This data-driven approach ensures that every hour spent studying is optimized for maximum impact.
                        </p>
                        {/* <p className='align-items-center monster-lrg-normal p-gap'>
                        For educators and administrators, this wealth of data opens up new possibilities for tailored teaching strategies, allowing them to become architects of personalized learning experiences. Virtu Prep also plays an active role in professional development, offering educators and administrators workshops, webinars, and training to keep them abreast of the latest in teaching strategies and technologies.
                        </p> */}
                       


                        <heding className='montser-lrg-bold align-items-center py-4'>Beyond Test Scores: Building Confidence and Skills for Life:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        At Neo Test Prep, we recognize that succeeding on standardized tests is just one step in a lifelong educational journey. Our commitment to our students extends beyond numerical scores, focusing also on building confidence, resilience, and the strategic thinking skills that are valuable both inside and outside the examination hall.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Our community of learners, educators, and parents is at the heart of our mission. We provide resources and support that empower everyone involved in the test preparation process, fostering a collaborative environment that uplifts and motivates.
                        </p>
                        
                    

                        <heding className='montser-lrg-bold align-items-center py-4'>Commitment to Excellence, Innovation, and Integrity: </heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        As we look to the future, Neo Test Prep remains dedicated to continuous innovation, unwavering in our commitment to providing the most effective, ethical, and cutting-edge test preparation services available.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Informed by ongoing research, user feedback, and the latest in educational technology, we strive to keep our platform and methodologies at the forefront of the test prep industry, ensuring our students always have access to the best possible resources.
                        </p>
                       

                        <heding className='montser-lrg-bold align-items-center py-4'>Join the Neo Test Prep Community:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Embark on a journey with Neo Test Prep, where ambitious goals are within reach, and every learner is equipped with the tools for success. We invite you to become part of a community where excellence is the standard, preparation meets opportunity, and every test is a step towards achieving your dreams. Welcome to the new standard in test preparation. Welcome to Neo Test Prep.
                        </p>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
            {/* </> */}
        </div>
        // </div>
        // </>


    );
};

export default Aboutus;

