// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-school-text {
    margin-bottom: 13vw !important;

}

.video-school-text2 {  
    margin-left: 10vw;
    }

.text-padding {
    padding-left: 9.5vw;
}

.center-text-padding{
    padding-left: 17vw;
  }

.text-top {
    padding-top: 9vw;
}

@media screen and (max-width: 575px){ 
    .text-top {
        padding-top: 50vw;
    }

    .center-text-padding{
        padding-left: 0vw;
      }
}

   
`, "",{"version":3,"sources":["webpack://./src/components/videobackground.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;;AAElC;;AAEA;IACI,iBAAiB;IACjB;;AAEJ;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;EACpB;;AAEF;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,iBAAiB;MACnB;AACN","sourcesContent":[".video-school-text {\n    margin-bottom: 13vw !important;\n\n}\n\n.video-school-text2 {  \n    margin-left: 10vw;\n    }\n\n.text-padding {\n    padding-left: 9.5vw;\n}\n\n.center-text-padding{\n    padding-left: 17vw;\n  }\n\n.text-top {\n    padding-top: 9vw;\n}\n\n@media screen and (max-width: 575px){ \n    .text-top {\n        padding-top: 50vw;\n    }\n\n    .center-text-padding{\n        padding-left: 0vw;\n      }\n}\n\n   \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
