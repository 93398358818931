
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Parents = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("parentSectionActive");
  }, []);

  return (
    // <>

    <div >
      <Navbar />
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <div style={{ height: '100%', paddingTop: '100px' }} className='body-color-p'>
        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Parents</h4>
            <hr className='mt-5'></hr>
          </section>
          <section className='vp-section'>
            <div className='row'>
              <div className='col-sm-6 flex-column align-items-start inner-container gap-5'>

                <div className='mb-0 w-100'>
                  <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                    <div className='job-width lh-lg '>
                      <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>JOB:</h5>
                      <span className='montser-mdm-2 common-textshadow ft-2-5vh' >ELEVATE YOUR CHILD’S EXAM SCORES </span>
                      <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>by creating the best scientifically-proven environment for your child to learn by increasing their focus and attention while studying.</p>
                    </div>
                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                  </div>
                </div>


                <div className='mb-0 w-100'>
                  <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                    <div className='job-width lh-lg '>
                      <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>JOB:</h6>
                      <span className='montser-mdm-2 common-textshadow ft-2-5vh'>VALIDATE YOUR CHILD’S GROWTH</span>
                      <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>by using advanced tools to measure and display the progress of your child, each step of the way, in real-time.</p>
                    </div>
                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                  </div>
                </div>


                <div className='mb-0 w-100'>
                  <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                    <div className='job-width lh-lg '>
                      <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>JOB:</h5>
                      <span className='montser-mdm-2 common-textshadow ft-2-5vh'  >MAXIMIZE TIME AND OUTCOMES</span>
                      <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>by eliminating waste and surprises. Know the strengths and weaknesses of your child in real-time and intervene guilt-free to help your child shine.</p>
                    </div>
                    <div><i className='demo-icon icon-checkiconsolid' /></div>
                  </div>
                </div>

              </div>
              <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                  <img src='images/Parents_Image_1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img me-auto" />
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className='vp-section'>
          <div className='justify-content-center'>
            <div className=' d-flex flex-column align-items-start text-left gap-5'>
              <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Discover the power of Virtu Prep for your child's academic success!</p>
              <div className="width-100 ">
                <img src='images/Widescreen Parents_1.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
              </div>
            </div>
          </div>
        </section>


        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
         

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-7 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Our cutting-edge, neuroscience-backed platform offers personalized learning experiences tailored to their unique needs.</p></div>
                </div>
              </div>
              <div className='col-sm-7'>
                <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                  <img src='images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Our cutting-edge, neuroscience-backed platform offers personalized learning experiences tailored to their unique needs.</p></div>
                </div>
              </div>
            </div>
          </section>


         



          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'> Stay engaged with your child's progress through real-time assessments and celebrate achievements together.</p>
                  </div>
                </div>
                <div className='d-flex pb-3 align-items-center justify-content-center'>
                  <button type="button" className="outline-lightgreen py-0 montser-mdm-2 rounded-top rounded-0 btn btn-outline-light d-none d-none">
                    <span className='text-yellow '>Meet us</span>
                  </button>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                  <img src='images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-7 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">Boost motivation and instill a love for learning with our reward-based system and gamification.</p></div>
                </div>
              </div>
              <div className='col-sm-7'>
                <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                  <img src='images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">Boost motivation and instill a love for learning with our reward-based system and gamification.</p></div>
                </div>
              </div>
            </div>
          </section>



          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>Strengthen the educational bond and give your child the advantage they deserve with Virtu Prep. </p>
                  </div>
                </div>
                <div className='d-flex pb-3 align-items-center justify-content-center'>
                  <button type="button" className="outline-lightgreen py-0 montser-mdm-2 rounded-top rounded-0 btn btn-outline-light d-none d-none">
                    <span className='text-yellow '>Meet us</span>
                  </button>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                  <img src='images/school image 5.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                </div>
              </div>
            </div>
          </section>



          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-7 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">Embrace a brighter future for your family's learning journey.</p></div>
                </div>
              </div>
              <div className='col-sm-7'>
                <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                  <img src='images/Classroom Image 3.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                  <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">Embrace a brighter future for your family's learning journey.</p></div>
                </div>
              </div>
            </div>
            
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>Hit the Meet Us button to schedule a demonstration with one of our dedicated Team Members.</p>
                  </div>
                </div>
                <div className='d-flex pb-3 align-items-center justify-content-center'>
                  <button type="button" className="outline-lightgreen py-0 montser-mdm-2 rounded-top rounded-0 btn btn-outline-light d-none d-none">
                    <span className='text-yellow '>Meet us</span>
                  </button>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                  <img src='images/school image 2.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                </div>
              </div>
                         </div>
            <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                      <MyPopupbutton/> 
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
          </section>

        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Parents;

